import React from "react";

import Layout from "../components/layout";
import SEO from "../components/seo";
import { graphql, Link } from "gatsby";
import BackgroundImage from "gatsby-background-image";
import K from "../images/kaputtmacher-seal-animated.gif";

export const data = graphql`
  query {
    studioDrumsetFar: file(relativePath: { eq: "studio_drumset_far.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    studioWoodwall: file(relativePath: { eq: "studio_woodwall_jochen.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    studioB: file(relativePath: { eq: "studio_b.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    studioControl: file(relativePath: { eq: "studio_control.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
    studioChalkboard: file(relativePath: { eq: "studio_chalkboard.jpg" }) {
      childImageSharp {
        fluid(quality: 80, maxWidth: 2880) {
          ...GatsbyImageSharpFluid_withWebp
        }
      }
    }
  }
`;

export default ({ data }) => (
  <Layout>
    <SEO
      keywords={[
        `music production`,
        `Musikproduktion`,
        `music producer`,
        `Musikproduzent`,
        `recording studio`,
        `Tonstudio`,
      ]}
      title="Music Production in Bochum, Germany"
    />

    <BackgroundImage
      fluid={data.studioWoodwall.childImageSharp.fluid}
      className="min-h-screen h-doublescreen bg-fixed bg-center bg-cover border-white"
    >
      <div className="c-topblock flex items-center">
        <div className="bg-white max-w-lg md:w-2/3 lg:w-1/2 flex z-10 mx-auto relative">
          <div className="px-4 md:px-4 lg:px-14 py-10 pt-32 pb-16 md:py-16 flex items-center">
            <div>
              <h1 className="mb-4 text-6xl lg:text-5xl xl:text-6xl font-semibold leading-negative">
                Weird sounds &amp; new ideas
                <br className="block md:hidden block:xl" />
              </h1>

              <p className="text-xl">
                Music Production based in Bochum, Germany and working world
                wide.{" "}
                <a href="#contact" className="greenline">
                  Get in touch
                </a>{" "}
                or learn more about{" "}
                <a href="#about-us" className="greenline">
                  what we do
                </a>
                .
              </p>
            </div>
          </div>
        </div>
      </div>
      <div class="md:w-1/3 lg:w-full md:h-screen relative">
        <div
          style={{
            position: "fixed",
            zIndex: "1",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
          }}
        >
          <div
            style={{
              animation:
                "hover-two 0.8s infinite alternate cubic-bezier(0.19, 1, 0.22, 1)",
            }}
          >
            <div
              className="bg-white inline-block p-6 md:p-6 rounded-full"
              style={{
                animation: "hover 1.4s infinite alternate ease-in-out",
              }}
            >
              <img src={K} className="w-16 md:w-18" alt="" />
            </div>
          </div>
        </div>
      </div>
    </BackgroundImage>

    <BackgroundImage
      fluid={data.studioB.childImageSharp.fluid}
      loading="eager"
      fadeIn="false"
      className="md:min-h-screen bg-fixed bg-center bg-cover flex-row-reverse border-white "
    ></BackgroundImage>

    <BackgroundImage
      fluid={data.studioDrumsetFar.childImageSharp.fluid}
      loading="eager"
      fadeIn="false"
      className="md:flex min-h-screen bg-fixed bg-center bg-cover md:h-doublescreen flex-row-reverse border-white"
    >
      <div
        className="bg-white md:w-1/2 md:min-h-screen md:h-screen flex items-center"
        id="about-us"
      >
        <div className="px-4 sm:px-12 lg:px-14 py-10 sm:py-10 pt-32 md:py-16 pb-16 z-10">
          <div class="max-w-md">
            <h2 className="text-4xl font-semibold leading-none mb-3">
              About us
            </h2>

            <p className="mb-4">
              We strongly believe in bold ideas over safe ones. If you want to
              create something new and stand out, we'd love to help you out with
              production and (remote) mixing.
            </p>

            <p className="mb-4">
              Our productions have reached millions of listeners on Spotify and
              YouTube. In addition to single, EP and LP productions, we're
              creating the{" "}
              <Link to="/sessions" className="greenline">
                Kaputtmacher Sessions
              </Link>{" "}
              live from our studio in Bochum, Germany.
            </p>

            <p>
              <a className="button px-3 mr-3 pt-2 pb-1" href="#clients">
                Select clients
              </a>
              or{" "}
              <a
                className="button button--heavy px-3 ml-2 pt-2 pb-1"
                href="#contact"
              >
                get in touch
              </a>
            </p>
          </div>
        </div>
      </div>
    </BackgroundImage>

    <BackgroundImage
      fluid={data.studioControl.childImageSharp.fluid}
      loading="eager"
      fadeIn="false"
      className="md:flex min-h-screen bg-fixed bg-center bg-cover md:h-doublescreen border-white"
      id="clients"
    >
      <div className="bg-white md:w-1/2 md:min-h-screen md:h-screen flex items-center justify-end">
        <div className="px-4 sm:px-12 lg:px-14 py-10 sm:py-10 pt-32 md:py-16 pb-16 z-10 max-w-md ">
          <h2 className="text-3xl font-semibold leading-none mb-3">
            Select clients
          </h2>

          <p class="mb-4">
            We have worked with some wonderful and memorable people. Thank you.
          </p>

          <ul className="list-none leading-normal text-sm p-0 mb-2">
            <li className="inline-block mr-2">Sony Music</li>
            <li className="inline-block mr-2">Century Media Records</li>
            <li className="inline-block mr-2">Warner Music Group</li>
            <li className="inline-block mr-2">Uncle M</li>
            <li className="inline-block mr-2">Homebound Records</li>
          </ul>

          <ul className="list-none leading-normal text-sm p-0">
            <li className="inline-block mr-2">KMPFSPRT</li>
            <li className="inline-block mr-2">Travels &amp; Trunks</li>
            <li className="inline-block mr-2">Shoreline</li>
            <li className="inline-block mr-2">DONOTS</li>
            <li className="inline-block mr-2">Marathonmann</li>
            <li className="inline-block mr-2">FJØRT</li>
            <li className="inline-block mr-2">Die Kassierer</li>
            <li className="inline-block mr-2">Mr Linus</li>
            <li className="inline-block mr-2">Van Holzen</li>
            <li className="inline-block mr-2">DOTE</li>
            <li className="inline-block mr-2">Goodbye Fairground</li>
            <li className="inline-block mr-2">Joey Cape</li>
            <li className="inline-block mr-2">Chris Cresswell</li>
            <li className="inline-block mr-2">Mutiny On The Bounty</li>
            <li className="inline-block mr-2">Banana Roadkill</li>
            <li className="inline-block mr-2">Anti-Flag</li>
            <li className="inline-block mr-2">Mouse On The Keys</li>
            <li className="inline-block mr-2">Agador Spartacus</li>
            <li className="inline-block mr-2">Muncie Girls</li>
            <li className="inline-block mr-2">Kosslowski</li>
            <li className="inline-block mr-2">Elm Tree Circle</li>
            <li className="inline-block mr-2">Alight Havens</li>
            <li className="inline-block mr-2">Blackout Problems</li>
            <li className="inline-block mr-2">Finder</li>
            <li className="inline-block mr-2">In Welten</li>
            <li className="inline-block mr-2">Apologies, I Have None</li>
            <li className="inline-block mr-2">Snareset</li>
            <li className="inline-block mr-2">Louder Than Wolves</li>
            <li className="inline-block mr-2">Lygo</li>
            <li className="inline-block mr-2">Berlin Syndrome</li>
            <li className="inline-block mr-2">ANTILOPE</li>
            <li className="inline-block mr-2">Radio Havanna</li>
            <li className="inline-block mr-2">Smile And Burn</li>
            <li className="inline-block mr-2">Matula</li>
            <li className="inline-block mr-2">Idle Class</li>
            <li className="inline-block mr-2">Mee Shome</li>
            <li className="inline-block mr-2">Banda Senderos</li>
            <li className="inline-block mr-2">Bartitsu</li>
            <li className="inline-block mr-2">The Prosecution</li>
            <li className="inline-block mr-2">&hellip;</li>
          </ul>
        </div>
      </div>
    </BackgroundImage>
    <div class="border-b border-gray-200 bg-white relative">
      <div
        id="contact"
        className="px-4 sm:px-8 lg:px-16 py-10 sm:py-10 md:py-16 lg:py-20 md:w-2/3 max-w-xl flex items-center z-10 mx-auto text-center"
      >
        <div className="">
          <h2 className="text-4xl leading-none font-semibold mb-3">
            Get in touch <span className="text-3xl wobble">🙌</span>
          </h2>

          <p className="text-lg mb-6">
            Feel free to just get in touch and talk about whatever you have in
            mind. Shoot us a mail (in English or German), and you'll hear back
            from in no time.
          </p>

          <a
            className="button button--heavy px-3 pt-2 pb-1 text-lg"
            href="mail&#116;o&#58;hello&#64;&#107;a&#37;7&#48;utt&#109;a&#99;he%72&#46;&#37;63o&#109;?body=(Feel free to write in English or German)"
          >
            Write a Mail
          </a>
        </div>
      </div>
    </div>
  </Layout>
);
